export const ONE_YEAR = 31536000;
export const getDefYear = (league = "") => {
  if (league === "") {
    return "2024";
  } else if (league === "nba" || league === "NBA") {
    return "2025";
  } else if (league === "ncaam" || league === "college_mens" || league === "NCAA" || league === "ncaa") {
    return "2025";
  } else if (league === "ncaaw" || league === "college_womens" || league === "NCAAW") {
    return "2024";
  } else if (league === "wnba" || league === "WNBA") {
    return "2024";
  }
  return "2024";
};
export const getYearArray = (league = "") => {
  if (league === "") {
    return ["2022", "2023", "2024"];
  } else if (league === "nba" || league === "NBA") {
    return ["2020", "2021", "2022", "2023", "2024", "2025"];
  } else if (league === "ncaam" || league === "college_mens" || league === "NCAA" || league === "ncaa") {
    return ["2020", "2021", "2022", "2023", "2024", "2025"];
  } else if (league === "ncaaw" || league === "college_womens" || league === "NCAAW") {
    return ["2021", "2022", "2023", "2024"];
  } else if (league === "wnba" || league === "WNBA") {
    return ["2020", "2021", "2022", "2023", "2024"];
  } else if (league === "japan_b1" || league === "JAPAN_B1") {
    return ["2020", "2021", "2022", "2023", "2024"];
  } else if (league === "el_salvador") {
    return ["2022", "2023", "2024"];
  }
  return ["2022", "2023", "2024"];
};

export const miscTableHeader = [
  {
    title: "Game Date & Time",
    showImage: false,
    sortable: false,
    key: "Date",
    classList: ["mm-stretch-column"],
  },
  {
    title: "NBA Basketball Matchup",
    showImage: false,
    sortable: false,
    key: "Matchup",
    classList: ["mm-stretch-column"],
  },

  {
    title: "Predicted Matchup Score",
    showImage: false,
    sortable: false,
    key: "SQProjection",
    classList: ["mm-stretch-column"],
  },

  {
    title: "Expert Prediction",
    showImage: false,
    sortable: false,
    key: "Plays",
    classList: ["mm-stretch-column"],
  },
];

export const teamStatsHeader = [
  {
    title: "Team",
    showImage: false,
    sortable: true,
    key: "Team_Name",
  },
  {
    title: "Conference",
    showImage: false,
    sortable: true,
    key: "Conference",
  },
  {
    title: "adj SQ",
    showImage: false,
    sortable: true,
    key: "Adjusted_Shot_Quality",
    tooltip: {
      title: "Adjusted Shot Quality",
      text: "Adjusted ShotQuality difference: adjOFF SQ minus adjDEF SQ.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjOFF SQ",
    showImage: false,
    sortable: true,
    key: "Offensive_Shot_Quality",
    rankKey: "Offensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Offensive ShotQuality",
      text: "Offensive SQ points divided by possessions, but adjusting for strength of schedule for each team",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjDEF SQ",
    showImage: false,
    sortable: true,
    key: "Defensive_Shot_Quality",
    rankKey: "Defensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Defensive ShotQuality",
      text: "Defensive SQ points divided by possessions, but adjusting for strength of schedule for each team.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "Rim & 3 Rate",
    showImage: false,
    sortable: true,
    key: "rim_and_3_rate",
    tooltip: {
      title: "Rim & 3 Rate",
      text: "Percent of possession that resulted in a three pointer or a shot at the rim (the two most valued shots!)",
      link: "/stats-explained#Rim&3",
    },
  },
  {
    title: "Record",
    showImage: false,
    sortable: true,
    key: "Actual_Record",
  },
  {
    title: "SQ Record",
    showImage: false,
    sortable: true,
    key: "SQ_Record",
    classList: ["min-width-100px"],
    tooltip: {
      title: "Shot Quality Record",
      text: "Projected record based on shots taken by both teams. Total accumulation of “win game %” stat.",
      link: "/stats-explained#Rim&3",
    },
  },
  {
    title: "Win%",
    showImage: false,
    sortable: true,
    key: "Actual_Win_Percentage",
  },
  {
    title: "SQ Win%",
    showImage: false,
    sortable: true,
    key: "SQ_Win_Percentage",
    tooltip: {
      title: "Shot Quality Win Percentage",
      text: "Projected win percentage based on Shot Quality",
    },
  },
  {
    title: "Record Luck",
    showImage: false,
    sortable: true,
    key: "Record_Luck",
    tooltip: {
      title: "Record Luck",
      text: "(SQ Win Percentage - Actual Win Percentage) *100. If positive, team is due for positive regression. If negative, team is due for negative regression!",
      link: "/stats-explained#record-luck",
    },
  },
];
